import React, {useEffect, useRef, useState} from 'react';
import section from "../../styles/section.module.css";
import {motion} from "framer-motion";
import arrow from "../../styles/scroll.module.css";
import fader from "../../styles/fader.module.css";

interface FutureProps {
    activeSection: string;
}

interface TimePeriod {
    Time: string;
    description: string;
    description2?: string;
}

const containerVariants = {
    hidden: { opacity: 1 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.3,
        },
    },
};

const contentVariants = {
    hidden: { opacity: 0, x: -400 },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 1.2,
            ease: "easeInOut",
        },
    },
};

const Future: React.FC<FutureProps> = ({ activeSection }) => {
    const sectionRef = useRef<HTMLDivElement | null>(null);
    const [isScrollIndicatorVisible, setScrollIndicatorVisible] = useState(true);
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const myFuture = `My future specialisation will be in Software Engineering with Java/Python, SQL, Docker, and AWS.`;
    const myFuture2 = "On a more personal side, I have an ongoing project developing a gamified language learning platform and am looking for opportunities to work within the defence industry/govt to fulfill my childhood ambition to serve and support the community.";
    const aboutData: TimePeriod[] = [
        { Time: 'My Future', description: myFuture, description2: myFuture2 },
    ];

    const handleClick = () => {
        const aboutSection = document.getElementById("education");
        if (aboutSection) {
            window.scrollTo({
                top: aboutSection.offsetTop + 180,
                behavior: "smooth",
            });
        }
        setScrollIndicatorVisible(false);
    };

    useEffect(() => {
        if (activeSection === 'future') {
            setScrollIndicatorVisible(true);
        } else {
            setScrollIndicatorVisible(false);
        }
    }, [activeSection]);

    const scrollIndicatorVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { delay: 0.1 },
        },
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            });
        });

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <section
            id="future"
            className={`${fader.container} ${activeSection === 'future' ? fader.fadeIn : fader.fadeOut}`}
            ref={sectionRef}
        >
            <motion.div
                variants={containerVariants}
                initial="hidden"
                animate={isVisible ? "visible" : "hidden"}
                onAnimationComplete={() => {
                    if (isVisible && activeSection === 'about') {
                        setScrollIndicatorVisible(true);
                    }
                }}
            >
                {aboutData.map((time, index) => (
                    <motion.div
                        key={index}
                        variants={contentVariants}
                    >
                        <p><strong>{time.Time}</strong></p>
                        <p>{time.description}</p>
                        <p>{time.description2}</p>
                    </motion.div>
                ))}
            </motion.div>
        </section>
    );
};

export default Future;