import React, { useEffect } from 'react';
import History from '../components/about/History';
import Education from '../components/about/Education';
import Present from '../components/about/Present';
import Future from '../components/about/Future';
import Timeline from "../components/about/Timeline";

interface AboutPageProps {
    activeSection: string;
    setActiveSection: (section: string) => void;
}

const AboutPage: React.FC<AboutPageProps> = ({ activeSection, setActiveSection }) => {

    useEffect(() => {
        setActiveSection('education');
    }, [setActiveSection]);

    useEffect(() => {
        const handleScroll = () => {
            const sections = ['education', 'timeline', 'history', 'present', 'future'];
            for (let i = 0; i < sections.length; i++) {
                const sectionElement = document.getElementById(sections[i]);
                if (sectionElement) {
                    const rect = sectionElement.getBoundingClientRect();
                    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
                    if (rect.top <= windowHeight / 2 && rect.bottom >= windowHeight / 2) {
                        setActiveSection(sections[i]);
                        break;
                    }
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [setActiveSection]);

    return (
        <main>
            <section id="education" style={{paddingTop: '0'}}>
                <Education activeSection={activeSection}/>
            </section>
            <section id="history">
                <History activeSection={activeSection}/>
            </section>
            <section id="present">
                <Present activeSection={activeSection}/>
            </section>
            <section id="future">
                <Future activeSection={activeSection}/>
            </section>
        </main>
    );
};

export default AboutPage;